// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1_webpack-cli@5.1.4_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-overview-supported-content[data-v-70d3722c] {
  margin-top: 6px;
  margin-bottom: 3px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,OAAO;EACP,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".promotion-overview-supported-content[data-v-70d3722c] {\n  margin-top: 6px;\n  margin-bottom: 3px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
