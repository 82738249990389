import React, { useContext } from 'react';
import { Radio } from '@didi/ec-base';
import { PublicStore } from '../../../../utils';
import PageletServices from '../../../../services';  //页面请求方法
import classNames from 'classnames';
import './style.scoped.less';
import './style.less';

const BarChart = () => {
  // 样式
  const prefixCls = 'promotion-overview-supported-bar-chart';
  const classes = classNames(prefixCls);
  // 状态
  const { stateStore, stateDispatch, props } = useContext(PublicStore);
  const { distributionCode, distributions, searchValue, newHeaders } = stateStore;
  const {
    params: { deptCodes = [] },
  } = props;

  // 人员列表请求
  const requestPersonList = async progressDistributionCode => {
    try {
      const paramsObj = {
        promotionScene: 'DHR_WORKBENCH_BP_WINDOW',
        pageNum: Number(1),
        pageSize: Number('10'),
        deptCodes,
        progressDistributionCode,
        selectedEmpIds: searchValue ? [searchValue] : []
      };
      const { data } = await PageletServices.postSearchAndDistribution('', newHeaders, paramsObj);
      const { progressDistribution, pageResult, searchParam } = data;
      const { distributionCode } = searchParam;
      stateDispatch({
        ...searchParam,
        distributions: progressDistribution?.distributions,
        idpNum: progressDistribution?.idpNum,
        idpPublishedNum: progressDistribution?.idpPublishedNum,
        totalNum: progressDistribution?.totalNum,
        distributionName: progressDistribution?.distributions?.find(item => item.code === distributionCode)?.desc,
        list: pageResult?.list,
        hasMore: pageResult?.list.length < pageResult?.total,
        pageNum: Number(pageResult?.pageNum),
        resultLoading: false
      });
    } catch (error) {
      stateDispatch({
        pageNum: Number('1'),
        list: [],
        resultLoading: false
      });
    }
  };

  // 柱子颜色枚举
  const enumBackground = {
    0: 'linear-gradient(180deg, #FFDDC8 -3%, #FF8E45 70%)',
    1: 'linear-gradient(180deg, #FFDDC8 -3%, #FF8E45 70%)',
    2: 'linear-gradient(180deg, #75D9AE 0%, #00B365 100%)',
    3: 'linear-gradient(180deg, #EBE7E7 0%, #C7C7C7 100%)'
  };


  return (
    <div className={classes}>
      {distributions?.map((item, index) => {
        const { code, desc, num, rate } = item;
        const decimalPoint = rate.includes('.');
        let str = decimalPoint ? rate.split('.')[0] : 0;
        return (
          <div className="eachState">
            <div className={`graphical ${code === distributionCode && 'checked'}`}>
              <p className="numericalValue">
                <span className="num">{num || 0}</span>
                <span className="people">人</span>
              </p>
              <div
                className="bar"
                style={{
                  height: `${parseInt(177 * Number(str / 100), 10)}px`,
                  background: enumBackground[index]
                }}
              />
            </div>
            <div className="line" />
            <Radio
              value={code}
              checked={code === distributionCode}
              onChange={e => {
                stateDispatch({
                  distributionCode: e.target.value,
                  distributionName: desc,
                  resultLoading: true
                });
                requestPersonList(e.target.value);
              }}
            >
              {desc}
            </Radio>
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
