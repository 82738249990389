import React, { useContext, useMemo } from 'react';
import { Dropdown } from '@didi/ec-base';
import { PublicStore, recordTrackEvent } from '../../utils';
import { RightThickOutlined, MailOutlined } from '@didi/iconflow/ec';
import { DHRCardButton } from '@didi/ec-ehr-base';
import classNames from 'classnames';
import './style.scoped.less';

const PageBottom = () => {
  // 样式
  const prefixCls = 'promotion-overview-supported-Bottom';
  const classes = classNames(prefixCls);

  const { stateStore, props } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { helpInfo } = apolloConfig
  const { name, options, rightName, righturl } = helpInfo;
  const {
    params: { apiHost }
  } = props;

  const items = useMemo(() => {
    return options?.map(item => ({
      key: item?.label,
      label: <a
        target="_blank"
        rel="noopener noreferrer"
        href={item?.url}
        style={{ whiteSpace: 'nowrap' }}
        onClick={() => {
          recordTrackEvent({ click_source: Number('3') })
        }}
      >
        {item?.label}
      </a>
    }));
  }, [options]);

  const handleRightLink = e => {
    e.preventDefault();
    window.open(righturl, '_blank');
    if (apiHost.includes('o.didichuxing.com')) {
      recordTrackEvent({ click_source: Number('3') }); // 记录埋点
    }
  };

  if (!items) {
    return null;
  }

  return (
    <div className={classes}>
      {
        options?.length > 1 ? (
          <Dropdown
            overlayClassName='dropdown_menu_overview_self'
            menu={{
              items,
              onClick: () => {
                if (apiHost.includes('o.didichuxing.com')) {
                  recordTrackEvent({ click_source: Number('3') }); // 记录埋点
                }
              }
            }}
            arrow={false}
          >
            <a
              onClick={e => {
                e.preventDefault();
                window.open(options[0]?.url, '_blank');
                if (apiHost.includes('o.didichuxing.com')) {
                  recordTrackEvent({ click_source: Number('3') }); // 记录埋点
                }
              }}
            >
              <p>{name}</p>
              <img src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_29ftiY2RZCRT98ssn4hu'} />
            </a>
          </Dropdown>
        ) : (
          <a onClick={handleLink}>
            <p>{options[0]?.label}</p>
            <img src='//img-ys011.didistatic.com/static/ddo_web_static/do1_JnQujALxAF8tHzVWSU4L' />
          </a>
        )
      }
      {
        righturl && (
          <DHRCardButton onClick={handleRightLink}>
            <MailOutlined cursor="pointer" size={14} />
            <span style={{ margin: '0 2px' }}>{rightName}</span>
            <RightThickOutlined cursor="pointer" size={14} />
          </DHRCardButton>
        )
      }
    </div>
  );
};

export default PageBottom;
